.welcome-page{
    @apply w-screen h-screen overflow-hidden flex items-center flex-col justify-start relative bg-[#f1f1f1];
}
.welcome-page h1{
    @apply text-center text-7xl pb-6 pt-24;
}
.welcome-page .home h1{
    @apply pt-44 pb-0;
}
.welcome-page p{
    @apply text-center  text-3xl pt-5;
}
.welcome-page .footer{
    @apply text-[#ffb200] w-[90%] h-20 border-t-2 border-[#dadada] bg-transparent absolute bottom-0 py-px;
}
.welcome-page ul{
    @apply flex flex-row justify-center items-center h-full;
}
.welcome-page ul li{
    @apply flex flex-col justify-center items-center px-5 cursor-pointer;
}
.welcome-page .footer ul li{
    @apply flex flex-row justify-center items-center;
}
.rounded-btn{
    @apply rounded-full bg-[#ffb200] border-8 border-[#ffb200] p-1 w-fit text-white;
}
.rounded-btn-white{
    @apply rounded-full bg-white border-8 border-[#ffb200] p-1 w-fit text-[#ffb200];
}
.welcome-page .footer a{
    @apply px-3;
}
.header{
    @apply w-[80%] flex flex-row bg-[#e1e1e1] absolute top-0 left-0 gap-4 justify-between items-center px-2;
}
.header div{
    @apply flex gap-5 items-center;
}
.header .header-logo{
    @apply pl-5;
}
.header .header-open{
    @apply flex gap-2 items-start pr-0;
}
.header img{
    @apply h-[75px];
}
.header .triangle-right {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 100px solid #e1e1e1;
    border-bottom: 50px solid transparent;
    @apply -mr-[108px];
}
.lang-select{
    @apply absolute top-5 right-5 cursor-pointer;
}
.lang-select img{
    @apply w-[80px]
}
.title{
    @apply text-center text-3xl pb-6 pt-32 flex justify-center flex-col items-center;
}
.title h3{
    @apply text-[#018498] first:text-[#a6a6a6] text-4xl first:text-3xl  pb-5;
}
.title-hr{
    @apply border-b border-[#018498] w-[320px] pt-5;
}
.welcome-page ul.user-group{
    @apply items-start;
}
.welcome-page ul.user-group li{
    @apply px-8;
}
.user-group img{
    @apply w-[220px] h-[380px];
}
.welcome-page ul.user-type{
    @apply items-start;
}
.user-group img, .user-type img{
    @apply w-[220px] h-[380px];
}
.user-question{
    @apply flex flex-col justify-start items-start pt-2;
}
.user-question h3{
    @apply text-5xl w-full text-left;
}
.user-question form{
    @apply pt-2;
}
.user-question fieldset{
    @apply px-5 flex flex-row items-center;
}
.user-question fieldset label{
    @apply py-2 text-3xl;
}
.user-question fieldset input[type=checkbox]{
    @apply mr-5;
}
.user-question fieldset input[type=number]{
    @apply mx-5 w-[80px] bg-green-500 pr-3 text-right text-white border-b-2 border-dotted outline-none border-black;
}
fieldset.btn-group{
    @apply flex flex-row justify-between items-center pt-5;
}
fieldset.btn-group button{
    @apply bg-indigo-500 px-6 py-3 rounded-xl text-white hover:bg-indigo-600;
}
fieldset.btn-group button.cc-btn{
    @apply bg-red-500 px-6 py-3 rounded-xl text-white hover:bg-red-600;
}