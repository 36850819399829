.login section{
    @apply grid grid-cols-1 md:grid-cols-2 font-body;
}
.login .left,.right{
    @apply w-full h-screen md:h-screen text-left;
}
.login .left{
    @apply min-h-[270px] h-fit md:h-screen items-center;
}
.login .right{
    @apply h-[70vh] md:h-screen;
}
.login .left{
    /* // @apply order-last sm:order-first; */
    @apply flex pt-[2vh] pb-[1vh] justify-center;
}
.login .right{
    @apply bg-gradient-to-r w-full from-cyan-500 to-blue-500 bg-cover bg-center flex items-end justify-end p-0;
}
.login .info{
    @apply rounded-lg bg-white bg-opacity-90 shadow-lg p-3 flex items-center justify-center w-2/3 lg:w-1/3;
}
.login .info a{
    @apply flex items-center justify-center;
}
.login .user-info{
    @apply flex w-full flex-row items-center;
}
.login .user-info li{
    @apply flex items-center justify-start flex-col pl-5;
}
.login .user-info li p,.login .user-info li h2,.login .user-info li div{
    @apply w-full flex;
}
.login form{
    @apply pt-4;
}
.login form p{
    @apply flex justify-center items-end w-full flex-nowrap;
}
.login form p a{
    @apply text-purple-450 text-sm ml-2 hover:text-purple-400;
}
.login .form{
    @apply min-w-[350px] sm:min-w-[435px] p-0 px-10;
}
.login h2{
    @apply text-black text-2xl font-body pb-2;  
}
.login p{
    @apply text-gray-500 text-sm;  
}
.login .authen-btn{
    @apply bg-white text-slate-500 flex items-center justify-center text-sm h-[48px] w-full ring-purple-300 ring-[1px] rounded-md border-[1px] p-3 mt-2 mb-5 hover:bg-purple-50;
}
.login .authen-btn img{
    @apply h-[30px] mr-2 inline;
}
.login .separator{
    @apply border-t border-gray-500;
}
.login .separator legend{
    @apply mx-auto px-2 text-gray-500 text-sm py-2; 
}
.login input{
    @apply h-[43px] ring-[1px] mr-2 inline ring-gray-300 rounded-md w-full px-3 text-sm;
}
.login fieldset{
    @apply py-1 flex justify-center;
}
.login .forget-pass a{
    @apply py-2 w-full text-right;
}
.login .forget-pass{
    @apply text-purple-450 text-sm text-right w-full hover:text-purple-400 justify-end flex;
}
.login button{
    @apply bg-purple-450 rounded text-white px-3 py-2 my-5 text-sm w-full;
    @apply hover:bg-purple-250 hover:text-purple-450 focus:ring-purple-250;
}
.login .user-img{
    @apply w-24 h-24 rounded-none shadow-lg my-5;
}
.login .logo img{
    @apply w-full;
}
.login .alert.alert-info,.login .alert.alert-danger{
    @apply rounded-lg bg-white bg-opacity-90 shadow-lg p-3 flex items-center justify-center w-2/3 lg:w-1/3;
    @apply bg-purple-150 border border-purple-450 rounded w-full py-5 my-5; 
}
.login .alert.alert-danger{
    @apply border-red-600 bg-red-50 text-red-600;
}
.login .alert.alert-info p,.login .alert.alert-danger p{
    @apply  text-purple-450 text-left w-full text-sm;
}
.login .alert.alert-danger p{
    @apply text-red-500;
}
.login .a-links{
    @apply cursor-pointer text-purple-450 text-sm text-right w-full hover:text-purple-400;
}
.login button i,.login fieldset i,.login .fa-key{
    @apply mx-2 text-red-400;
}
.login .red{
    @apply text-red-400 hover:text-red-500;
}