/* ------------------------------ Base ------------------------------ */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fontawesome/css/all.min.css";
:root {
    --color-primary-0: #4D2D24;
    --color-primary-1: #9A4B2D;
    --color-primary-2: #8F411E;
    --color-primary-3: #976A38;
    --color-primary-4: #D2B3AA;
  
    /* --color-text-0: #4D2D24; */
    --color-text: #fff;
    --color-text-0: #000;
    --color-text-1: #9A4B2D;
    --color-text-2: #8F411E;
    --color-text-3: #976A38;
    --color-text-4: #D2B3AA;
    --color-text-5: #FFEB77;
    --color-text-6: #ff9842;
    --color-text-7: #944ba7;
  
    --color-background-0: #191923;
    --color-background-1: #1e1e28e0;
  
    --color-box-shadow-0: #0f0f1433;
    --color-text-shadow-0: #fafafc66;
  
    --color-gradient-1-1: #1e1e28ed;
    --color-gradient-1-2: #1e1e28f5;
    --color-gradient-1-3: #1e1e28fc;
    --color-gradient-1-4: #1e1e28;
  
    --color-gradient-2-1: #252532fa;
    --color-gradient-2-2: #23232dfa;
}
@font-face {
    font-family: 'Kanit-Regular';
    src: url('~fonts/Kanit-Regular.ttf') format("truetype");
}
/* custom text selection */
::-moz-selection { /* Code for Firefox */
    background: #b482d1;
}
.collapse {
    visibility: visible;
}
::selection {
    background: #b482d1;
}
/* custom scrollbar */
::-webkit-scrollbar {
    width: 18px;
    @apply cursor-pointer;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    /* background-color: #d6dee1; */
    @apply bg-gray-300 bg-opacity-50 cursor-pointer;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
html, body { 
    @apply h-full;
}
body { 
    --bg-color: #fff;
    --txt-color: #000;
    --scrollbar-width: 0px;
    --hundred-vw: calc(100vw - var(--scrollbar-width, 0px));
    --hundred-vh: 100vh;
    --layout-ratio: calc(937px / 1920px);
    --hundred-vh-wratio: calc(var(--hundred-vw) * 0.488);
    --layout-padding: 0px;
    --grid-gap: 16px;
    --inner-width: calc(var(--hundred-vw) - 32px);
    --column-count: 6;
    --column-unit: calc((var(--inner-width) + var(--grid-gap)) / var(--column-count));
    --body-padding: 16px;
    @apply m-0 font-body w-[var(--hundred-vw)];
}
#root, section{
    @apply w-[var(--hundred-vw)];
}
section{
    @apply flex justify-center items-center text-[var(--txt-color)] bg-[var(--bg-color)];
}
h1{
    @apply text-5xl;
}
h2{
    @apply text-xl;
}
p{
    @apply text-xl;
}
/* ------------------------------ Font Size ------------------------------ */
.text-small{
    @apply text-base;
}
.text-normal{
    @apply text-lg;
}
.text-large{
    @apply text-xl;
}
.text-small h1{
    @apply text-5xl;
}
.text-normal h1{
    @apply text-5xl;
}
.text-large h1{
    @apply text-5xl;
}
.text-small h2{
    @apply text-4xl;
}
.text-normal h2{
    @apply text-4xl;
}
.text-large h2{
    @apply text-4xl;
}
.text-small h3{
    @apply text-3xl;
}
.text-normal h3{
    @apply text-3xl;
}
.text-large h3{
    @apply text-3xl;
}
.text-small h4{
    @apply text-2xl;
}
.text-normal h4{
    @apply text-2xl;
}
.text-large h4{
    @apply text-2xl;
}
.text-small h5{
    @apply text-xl;
}
.text-normal h5{
    @apply text-2xl;
}
.text-large h5{
    @apply text-3xl;
}
.text-small p,.text-small button, .text-small input, .text-small optgroup, .text-small select, .text-small textarea {
    @apply text-base;
}
.text-normal p,.text-normal button, .text-normal input, .text-normal optgroup, .text-normal select, .text-normal textarea {
    @apply text-lg;
}
.text-large p,.text-large button, .text-large input, .text-large optgroup, .text-large select, .text-large textarea {
    @apply text-xl;
}
.text-small footer p{
    @apply text-base;
}
.text-normal footer p{
    @apply text-lg;
}
.text-large footer p{
    @apply text-xl;
}
/* set text */
/* Go to top button */
.gototop-btn {
  @apply fixed justify-center items-center z-40 bottom-8 right-8 border-0 w-12 h-12 rounded-full drop-shadow-md shadow-xl bg-yellow-pallate-main bg-opacity-40 text-white font-bold cursor-pointer hover:bg-opacity-80;
}
.swiper-pagination{
    @apply text-right pr-5;
}
.highlight{
    background: linear-gradient(to bottom, #cfc09f 22%,#634f2c 24%, #cfc09f 26%, #cfc09f 27%,#ffecb3 40%,#d8aa4d 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
  }