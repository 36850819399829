
/* ------------------------------ Header ------------------------------ */
header{
    @apply sticky flex justify-center top-0 z-50 w-full md:opacity-95 min-h-[112px] bg-white;
    /* @apply shadow-lg border-b; */
}
header.drop-shadow{
    @apply shadow-lg;
    @apply w-full px-[32px] lg:px-[120px];
}
.nav{
    @apply w-full relative;
}
.nav-container{
    @apply w-full flex flex-col;
}
.nav-container .logo{
    @apply w-full flex justify-between mx-auto pt-5 pb-1;
}
.menu .container{
    @apply p-0 py-2;
}
.top-nav .menu{
    @apply flex justify-end pb-2 pr-5;
}
.top-nav {
    @apply flex-col flex text-white;
}
.top-nav .tool{
    @apply flex-row justify-end pb-2 pr-5 hidden md:flex;
}
.top-nav .language, .top-nav .font-size{
    @apply h-fit cursor-pointer p-0 flex ml-3 rounded text-sm items-center;
}
.top-nav li button{
    @apply h-[32px] flex items-center px-2 py-2 hover:text-gold-100 mr-1 last:mr-0 rounded-sm text-sm;
}
.top-nav .font-size li:first-child button{
    @apply text-xs;
}
.top-nav .font-size li:last-child button{
    @apply text-2xl;
}
.top-nav li.active button{
    @apply text-pallate-main bg-orange-100/[0.8] hover:text-gold-100 ;
}
.mobile-menu{
    @apply block lg:hidden ;
}
.desktop-menu{
    @apply hidden md:block w-full;
    /* @apply hidden lg:block w-full border-t border-dotted; */
}
.desktop-menu ul{
    @apply flex flex-row flex-wrap justify-start;
}
.desktop-menu ul li{
    /* @apply flex relative whitespace-nowrap px-5 py-2 pb-4; */
    @apply flex whitespace-nowrap px-5 py-2 pb-4;
}
.desktop-menu .submenu{
    @apply flex flex-col absolute top-10 z-10;
}
.desktop-menu a{
    @apply w-full whitespace-pre;
    text-decoration: none;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left,
                    var(--color-text),
                    var(--color-text) 50%,
                    var(--color-text-5) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    /* transition: all 0.3s ease-in-out; */
}
.desktop-menu a:hover{
    background-position: 0%;
}
/* .desktop-menu .submenu a{
    text-decoration: none;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left,
                    var(--color-background-0),
                    var(--color-background-0) 50%,
                    var(--color-text-6) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s ease-in-out;
}
.desktop-menu .submenu ul{
    @apply flex absolute flex-col top-10 shadow-lg ring-1 ring-pallate-main/5 bg-white/95 rounded;
}
.desktop-menu .submenu a:hover{
    background-position: 0%;
}
.desktop-menu .submenu ul li{
    @apply  hover:bg-pallate-main/5 min-w-[158px] max-w-[371px] first:rounded-t last:rounded-b;
}
.desktop-menu .submenu ul li,.desktop-menu .submenu ul li{
    @apply list-none hover:list-disc;
}
.desktop-menu ul li.active a,.desktop-menu ul li.active .submenu ul li.active a{
    @apply text-pallate-main;
} */
.mobile-menu{
    @apply text-black flex md:hidden justify-end;
}
.mobile-menu .mobile-nav{
    @apply hidden overflow-y-auto;
}
.mobile-nav-open{
    @apply bg-white/50 px-3 py-3 mb-3 rounded ring-1 ring-pallate-main/5 shadow-lg text-pallate-main hover:bg-white/80;
}
.mobile-menu .mobile-nav.active{
    @apply flex items-start flex-col fixed top-0 right-0 w-screen h-screen bg-white;
}
.mobile-menu .active ul{
    @apply flex w-full flex-col items-center;
}
.mobile-menu .active li{
    @apply w-full px-8 justify-start flex flex-col;
}
.mobile-menu .active .ul-menu-nav{
    @apply z-0 py-5 border-b border-pallate-main border-dotted;
}
.mobile-menu .active .top{
    @apply z-20 py-8;
}
.mobile-menu .active .ul-menu-nav li a,.mobile-menu .active .ul-menu-nav li p{
    @apply cursor-pointer w-full flex before:content-['\25AA'] before:mr-5 hover:text-pallate-main first-letter:text-pallate-main first-letter:text-xl py-2;
}
.mobile-menu .active .menu-link li a{
    @apply  before:content-['\279B'];
}
.mobile-menu .mobile-nav.active ul.top{
    @apply flex h-[50px] w-screen flex-row justify-between border-b border-pallate-main border-dotted;
}
.mobile-menu .mobile-nav.active ul.top li{
    @apply w-fit px-5 pl-8 text-pallate-main;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language{
    @apply relative h-[50px] flex items-center justify-center;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language img{
    @apply w-[36px] min-w-[36px];
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language div{
    @apply flex px-0 w-full flex-row justify-around items-center text-sm;
}
.mobile-menu .mobile-nav.active ul.top svg{
    @apply text-xs ml-5;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu{
    @apply right-0 top-10 absolute bg-white text-sm rounded text-pallate-main shadow-lg;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu li{ 
    @apply p-0;
}
.mobile-menu .mobile-nav.active ul.top li.mobile-menu-language .submenu li button{ 
    @apply hover:bg-pallate-main/5;
    @apply flex px-5 pr-8 w-full flex-row py-3 justify-center items-center;
}
.mobile-menu-language div{
    @apply whitespace-nowrap relative items-start justify-start z-10;
}
.mobile-menu-language img{
    @apply h-[15px] pr-3;
}
.mobile-menu .active .ul-menu-nav li p,.mobile-menu .active .ul-menu-nav li p a{
    text-decoration: none;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left,
                    var(--color-text-0),
                    var(--color-text-0) 50%,
                    var(--color-text-6) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    /* transition: all 0.3s ease-in-out; */
}
.mobile-menu .active .ul-menu-nav li p:hover,.mobile-menu .active .ul-menu-nav li p a:hover{
    transition: all 0.3s ease-in-out;
    background-position: 0%;
}
.mobile-menu .mobile-nav.active ul li.active .submenu ul li a{
    text-decoration: none;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left,
                    var(--color-text-0),
                    var(--color-text-0) 50%,
                    var(--color-text-6) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    /* transition: all 0.3s ease-in-out; */
}
.mobile-menu .mobile-nav.active ul li.active .submenu ul li a:hover{
    transition: all 0.3s ease-in-out;
    background-position: 0%;
}
.mobile-menu .mobile-nav.active  ul li.active a,.mobile-menu .mobile-nav.active ul li.active .submenu ul li.active a{
    @apply text-pallate-main;
}
.mobile-menu .active .ul-menu-info li.info{
    @apply text-pallate-main flex flex-row whitespace-pre-line items-end py-2;
}
.mobile-menu .active .ul-menu-info li.info h3{
    @apply pl-3;
}
.ul-menu-info li.open{
    @apply flex flex-row items-baseline whitespace-pre;
}
.ul-menu-info .info img{
    @apply min-w-[102px];
}
.ul-menu-info li.info{
    @apply my-2 mt-5 flex flex-col;
}
.ul-menu-info li.open p{
    @apply text-sm;
}
.ul-menu-info li.font-tool {
    @apply flex flex-row items-center my-2;
}
.ul-menu-info li.font-tool p{
    @apply text-sm whitespace-nowrap;
}
.ul-menu-info li.font-tool ul{
    @apply flex flex-row;
}
.ul-menu-info li.font-tool ul li{
    @apply w-fit px-1;
}
.ul-menu-info li.open p:first-child{
    @apply first-letter:text-pallate-main first-letter:text-xl text-base pr-3;
}
.ul-menu-info .social, .article-content .social {
    @apply flex flex-row;
}
.ul-menu-info .social ul, .article-content .social ul {
    @apply flex flex-row justify-center w-fit;
}
.ul-menu-info .social ul li, .article-content .social ul li{
    @apply mr-2 px-0 py-4;
}
.ul-menu-info .social .rounded-btn{
    @apply rounded-full bg-black p-3 w-fit text-white hover:bg-pallate-main;
}
header{
    @apply sticky;
}
header .container{
    @apply flex flex-col;
}
header .logo{
    @apply flex items-center justify-between w-full;
}
header .logo .logo-img img{
    @apply h-[55px];
}
header .logo .logo-img{
    @apply flex flex-row;
}
.header-info{
    @apply flex whitespace-pre flex-col justify-start text-sm pl-1 pb-2;
}
header .logo h3{
    @apply flex md:hidden lg:flex text-pallate-main whitespace-pre-line;
}
/* ------------------------------ Layout ------------------------------ */
.button_purple{
    @apply flex flex-row justify-center items-center gap-2 rounded-full bg-pallate-2 py-2 px-5 hover:bg-pallate-3 hover:underline;
}
.lang {
    @apply flex-row gap-4 relative h-[32px] hidden md:flex;
}
.lang button span{
    @apply h-[32px] flex items-center;
}
.font_size{
    @apply mr-[8px] flex-row gap-2 items-center justify-center hidden md:flex;
}
.font_size li.active button{
    @apply flex flex-row justify-center items-center rounded-md bg-pallate-2 px-2;
}
.font_size li{
    @apply h-[36px] w-[36px];
}
.font_size button{
    @apply text-xl h-[36px] w-[36px];
}
.font_size li:first-child button{
    @apply text-sm;
}
.font_size li:last-child button{
    @apply text-2xl;
}
/* .language{
    @apply absolute min-w-[136px] top-[36px] shadow-lg text-gray-400 bg-white invisible;
}
.language.show{
    @apply visible;
}
.language li button{
    @apply px-[16px] py-[8px] hover:underline hover:text-black/[0.8];
}
.language li.active{
    @apply font-semibold text-black/[0.8] ;
} */
.drawer_header{
    @apply p-[24px];
}
.drawer_header_top{
    @apply flex flex-row justify-between relative;
}
.drawer_header_top .menu_toggle span{
    @apply  ml-[16px];
}
.drawer_header_top .language_menu{
    @apply flex md:hidden;
}
.drawer_header_top .language_menu span{
    @apply flex md:hidden;
}
.drawer_header_top .language_menu button{
    @apply flex flex-row items-center;
}
.drawer_header_top .language.show{
    @apply right-0 top-[36px];
}
.drawer_header_top .language_menu.show button{
    @apply visible flex;
}
.language_menu .language{
    @apply hidden;
}
.language_menu .language.show{
    @apply flex min-w-[51.5px] flex-col;
}
.language_menu .language.show .active{
    @apply hidden;
}
.main-manu-container{
    @apply w-full md:w-1/2 h-auto md:h-[calc(var(--hundred-vh)*0.85)] md:overflow-y-auto  py-[18px] overflow-x-hidden;
}
.main_menu{
    @apply px-[28px];
}
.menu_list li{
    @apply flex ;
}
.menu_list li > button:first-child{
    @apply md:text-3xl opacity-40 hover:opacity-100 py-[24px];
}
.main_menu li > button:first-child{
    @apply w-full justify-start items-center text-start hover:translate-x-[1em] transition-transform duration-[0.3s] !text-xl md:!text-3xl;
}
.main_menu li.active{
    @apply opacity-100;
}
.main_menu li.active > button:first-child{
    @apply opacity-100 
}
.main_menu li button.plus{
    /* @apply hover:after:content-["+"] hover:after:ml-5 ; */
    @apply after:content-["+"]  after:ml-5;
}
.main_menu li.active button.plus{
    @apply after:content-["+"]  after:ml-5;
}
.info{
    @apply my-[24px] hidden md:flex flex-col w-0  md:w-full gap-4;
}
.info .search{
    @apply mt-[24px] flex flex-row w-full;
}
.search fieldset{
    @apply flex flex-row shadow-lg text-base hover:border-pallate-main flex-nowrap items-center border-pallate-main rounded-md h-[63px] p-[8px] bg-white w-full;
}
.search button{
    @apply rounded-md bg-pallate-4 hover:bg-pallate-3 text-white flex flex-row h-[38px] min-w-[38px] justify-center items-center;
}
.search select{
    @apply text-pallate-4 hover:border-pallate-main h-[46px]  outline-none rounded-l-md border-pallate-main border-r px-[16px] min-w-[170px];
}
.search input{
    @apply pl-5 text-black h-[46px] outline-none;
    @apply mb-2 md:mb-auto w-full;
}
.drawer_detail{
    @apply border-t  border-dark-4 mx-[24px] flex flex-col md:flex-row;
}
.info p{
    @apply whitespace-pre !leading-8 py-[18px] ;
}
.contact_info{
    @apply flex flex-row;
}
.contact_info ul{
    @apply w-1/2 ml-5;
}
.contact_info .map{
    @apply w-1/2;
}
.contact_info .map iframe{
    @apply w-full h-full;
}
.mobile-social{
    @apply flex md:hidden;
}
.mobile-social .social-link{
    @apply justify-end;
}
.social-link{
    @apply w-full flex pt-5 border-t border-dark-4 ;
}
.social-link li{
    @apply mr-2 flex justify-center items-start;
}
.social-link li button{
    @apply h-[48px] w-[48px] rounded-full hover:inset-1 flex justify-center items-center mr-[24px] mb-[16px];
    box-shadow: inset 0 0 0 2px #333;
    transition: box-shadow .15s;
}
.mobile-social .social-link li button{
    @apply ml-[8px] mr-0;
}
.social-link li button:hover{
    box-shadow: inset 0 0 0 2px #fff;
}
.social-info p{
    @apply pt-3;
}
.menu_list.active{
    @apply relative hidden;
}
.menu_list li{
    @apply flex flex-col;
}
.nav-menu{
    /* @apply hidden justify-start flex-col bg-[#1a1a1a] w-0 h-0 absolute; */
    position: relative;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    background: red;
    width: 100%;
    height: 100%;
    @apply hidden;
}
.active .nav-menu{
    @apply flex;
}
.menu_toolbar{
    @apply justify-between flex-row flex h-[75px] items-center ;
    /* border-b  border-dark-4 */
}
.menu_toolbar button{
    @apply hover:translate-x-0 flex justify-start items-center gap-4 opacity-40 hover:opacity-100;
}
.sub_menu_list button{
    @apply opacity-40 hover:opacity-100 text-left pl-5 py-2;
}
.footer{
    @apply bg-[var(--bg-color)] w-full px-[32px] lg:px-[120px] text-[var(--txt-color)];
}
.footer-header{
    @apply h-[160px] md:h-[140px] pt-10 md:pt-0 border-dotted border-b border-[var(--txt-color)] flex justify-start md:justify-between items-start md:items-center flex-col md:flex-row;
}
.footer-header img{
    @apply cursor-pointer h-[50px] block;
}
.footer-header p{
    @apply text-[var(--txt-color)];
}
.footer-detail{
    @apply flex justify-start md:justify-between items-start flex-col md:flex-row py-[40px] min-h-[calc(var(--hundred-vh)*1.2)] md:min-h-[calc(var(--hundred-vh)*0.45)] gap-5;
}
.footer-detail h5{
    @apply pb-[24px];
}
.footer-detail p{
    @apply pb-[24px] whitespace-pre;
}
.footer-detail .contact-us{
    @apply w-full md:w-1/3;
}
.footer-detail .social-link{
    @apply w-full flex pt-0 border-t-0 border-dark-4 flex-wrap pb-[20px];
}
.footer-detail .social-link li button{
    @apply h-[48px] w-[48px] rounded-full hover:inset-1 flex justify-center items-center mr-[24px] mb-[16px];
    box-shadow: inset 0 0 0 2px #fff;
    transition: box-shadow .15s;
}
.link a{
    @apply cursor-pointer hover:text-gold-100 hover:underline pb-2;
}
.link li{
    list-style-position: inside;
    list-style-type: disc;
    padding-bottom: .25rem;
    padding-top: .25rem;
    text-align: left;
    width: 100%
}
.visitors li{
    padding-bottom: .25rem;
    padding-top: .25rem;
}
.visitors li {
    display: flex;
    justify-content: space-between;
    @apply cursor-pointer hover:text-pallate-3 w-1/2 text-left;
}