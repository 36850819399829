
.menu{
    @apply w-1/4 hidden md:flex flex-col justify-start pb-10;
}
.right{
    @apply order-last;
}
.search{
    @apply flex flex-nowrap items-stretch w-full mb-4 flex-row;
}
.search-input{
    @apply flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-pallate-main focus:outline-none;
}
.search button{
    @apply px-6 py-2.5 bg-pallate-main/[0.8] text-white font-medium text-xs leading-tight uppercase rounded-r shadow-md hover:bg-pallate-main hover:shadow-lg focus:bg-pallate-main  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-pallate-main active:shadow-lg transition duration-150 ease-in-out flex items-center;
}
.left-menu-search select{
    @apply flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-pallate-main focus:outline-none;
}
.menu-section h4{
    @apply py-6;
}
.category ul {
    @apply list-none hover:list-disc;
}
.category li{
    @apply flex flex-row justify-start py-2; 
}
.category li{
    @apply w-full flex text-gray-700 cursor-pointer; 
    @apply hover:bg-pallate-1/[0.3] hover:text-pallate-main hover:rounded hover:px-5; 
}
.category li.active{
    @apply text-pallate-main; 
}
.booking-btn button{
    @apply w-full rounded-lg bg-pallate-3 text-white py-5 text-xl hover:bg-pallate-3/[0.8];
}
.booking-btn button i{
    @apply mr-2;
}
/* ------------------------------ header menu ------------------------------ */
.navbar{
    @apply relative;
}
.dropdown-menu-wrapper {
    @apply px-8 py-5;
}
.dropdown-menu {
    @apply absolute left-0 top-full mt-0 w-full rounded-md bg-white shadow-lg transition duration-150 ease-out hover:ease-in;
}
.dropdown-menu a{
    text-decoration: none;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left,
                    var(--color-text-7),
                    var(--color-text-7) 50%,
                    var(--color-text-6) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    /* transition: all 0.3s ease-in-out; */
}
.dropdown-menu a:hover{
    transition: all 0.3s ease-in-out;
}
.one-row-1.dropdown-menu {
    @apply left-auto right-auto pb-5 w-[450px];
}
.dropdown-menu-grid-4 {
    @apply grid gap-4 grid-cols-4;
}
.dropdown-menu-grid-1 {
  display: grid;
}
.menu-header {
    @apply block w-full pt-5 uppercase font-semibold  text-[var(--color-text-7)] cursor-pointer;
}
.menu-header small{
    @apply whitespace-pre;
}
.no-child{
    @apply mb-6 w-full whitespace-normal;
}
.menu-header a {
    @apply !text-pallate-4;
}
.dropdown-item .menu-header {
    @apply pt-0 pb-4;
}
a.menu-header {
    flex-wrap: wrap;
    white-space: normal;
    padding-bottom: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(148 75 167 / var(--tw-text-opacity));
}
.dropdown a.menu-header:hover {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.menu-link {
    @apply block pt-2 pb-5 text-left uppercase;
}
.one-row-1 .menu-link {
    @apply pb-0;
}
.dropdown-menu .menu-link li {
    @apply flex w-full flex-row flex-nowrap items-center p-0;
}
.menu-link li::before {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    --tw-text-opacity: 1;
    color: rgb(148 75 167 / var(--tw-text-opacity));
    --tw-content: '\25C6';
    content: var(--tw-content);
}
.menu-link li.sub::before {
    margin-left: 2rem;
    --tw-content: '\279B';
    content: var(--tw-content);
}
.menu-link a {
    width: 100%;
    justify-content: flex-start;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    --tw-text-opacity: 1;
    color: rgb(148 75 167 / var(--tw-text-opacity));
}
.menu-link a:hover {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}