.homepage{
    /* @apply h-[50vh] md:h-[70vh] w-[100vw]; */
    @apply h-[calc(var(--hundred-vh)/2)] md:h-[calc(var(--hundred-vh)*0.7)] w-[var(--hundred-vw)];
}
.slide-container .card{
    @apply w-[var(--hundred-vw)] relative;
}
.slide-container img{
    /* @apply h-[50vh] md:h-[70vh] w-[100vw] saturate-50 contrast-100; */
    /* @apply h-[calc(var(--hundred-vh)*0.5)] md:h-[calc(var(--hundred-vh)*0.7)] w-[var(--hundred-vw)] saturate-50 contrast-100; */
    @apply h-[calc(var(--hundred-vh)*0.5)] md:h-[calc(var(--hundred-vh)*0.7)] w-[var(--hundred-vw)] ;
}
.slide-container .info{
    /* @apply absolute text-white left-[10vw] bottom-[100px] whitespace-pre; */
    @apply absolute text-white left-[32px] lg:left-[120px] bottom-[32px] md:bottom-[100px] whitespace-pre h-[180px] md:h-[238px];
}
.slide-container .img-effect{
    @apply w-full h-full absolute top-0 left-0 bg-black opacity-30;
}
.slide-container h1{
    @apply !text-3xl md:!text-5xl;
}
.slide-container p{
    @apply py-[16px];
}
.slide-container button{
    @apply bg-pallate-4 shadow-lg px-[32px] py-[12px] rounded-full hover:bg-pallate-3 mt-[12px] absolute left-0 bottom-0;
}
.title {
    @apply relative uppercase flex items-center pb-[25px] opacity-50 w-full;
    letter-spacing: 1px;
    @apply h-[24px]
}
.title:before {
    /* @apply block border-b border-pallate-4 bottom-2 absolute q-; */
    @apply mr-[20px] bg-[var(--txt-color)] left-0 h-[1px] w-[30px] content-[''];
}
.info{
    @apply w-full relative h-full;
}
.blocks{
    @apply w-full relative h-auto flex justify-center items-center flex-col my-auto;
}
.about-us .bg-img{
    @apply absolute top-[100px] right-0 opacity-0;
} 
.about-us{
    @apply px-[32px] lg:px-[120px] relative h-[calc(var(--hundred-vh)*0.55)] sm:h-[calc(var(--hundred-vh)*0.45)] md:h-[calc(var(--hundred-vh)*0.5)] lg:h-[calc(var(--hundred-vh)*0.95)] xl:h-[calc(var(--hundred-vh)*0.8)] 2xl:h-[calc(var(--hundred-vh)*0.7)];
}
.about-us .title{
    @apply opacity-100;
}
.about-us p{
    @apply !text-xl md:!text-3xl lg:!text-4xl text-pallate-3 h-fit;
    line-height: 1.5 !important;
}
.view-more{
    @apply items-center gap-2 uppercase flex text-[var(--txt-color)] pt-[24px] after:content-["→"] after:text-2xl !text-base cursor-pointer;
}
.parallax{
    @apply h-[calc(.5*var(--hundred-vh))] relative;
    z-index: -1;
    clip-path: inset(0);
    margin-bottom: -1px;
}
.parallax img{
    position: fixed;
    top: 0;
    left: 0;
    height: calc(1.08 * var(--hundred-vh));
    z-index: -1;
    will-change: transform;
    transform: translate3d(0,calc(-8% * var(--scroll-percent)),0);
}
.activity{
    @apply px-[32px] lg:px-[120px] relative h-[calc(var(--hundred-vh)*0.95)] sm:h-[calc(var(--hundred-vh)*1.2)] md:h-[calc(var(--hundred-vh)*1.1)];
}
.sub-title{
    @apply w-full flex justify-between items-center;
}
.activity-container{
    @apply min-h-[600px] w-full;
    /* @apply text-2xl; */
}
.activity-container .card{
    @apply border p-[40px] border-[#1111111a] my-[24px] [&>*:not(:first-child)]:border-l-0 h-full;
    /* @apply text-2xl; */
}
.activity-container .info{
    @apply flex flex-col;
}
.activity-container .image{
    @apply w-full aspect-square;
}
.activity-container img{
    @apply w-full aspect-square rounded-md;
}
.activity-container .slide-title h4{
    @apply inline-block line-clamp-2 pt-[40px] cursor-pointer;
    /* @apply text-2xl; */
}
.activity-container .detail{
    @apply py-5 opacity-75;
    /* @apply text-2xl; */
}
.exhibition{
    @apply px-[32px] lg:px-[120px] relative h-[calc(var(--hundred-vh)*0.95)] sm:h-[calc(var(--hundred-vh)*1.2)] md:h-[calc(var(--hundred-vh)*1.1)];
}
.exhibition-container{
    @apply min-h-[453px] w-full;
    /* @apply text-2xl; */
}
.exhibition-container .card{
    @apply my-[24px] [&>*:not(:first-child)]:border-l-0 ;
    /* @apply text-2xl; */
}
.exhibition-container .info{
    @apply flex flex-col;
}
.exhibition-container .image{
    @apply w-full aspect-square;
}
.exhibition-container img{
    @apply w-full aspect-square;
}
.exhibition-container .slide-title h4{
    @apply inline-block line-clamp-2 pt-[40px] cursor-pointer;
    /* @apply text-2xl; */
}
.exhibition-container .detail{
    @apply py-5 opacity-75 text-center;
    /* @apply text-2xl; */
}
.souvenir{
    @apply px-[32px] lg:px-[120px] relative h-[calc(var(--hundred-vh)*0.95)] sm:h-[calc(var(--hundred-vh)*1.2)] md:h-[calc(var(--hundred-vh)*1.1)];
}
.souvenir .blocks{
    @apply items-start justify-start mt-[90px];
}
.souvenir-container{
    @apply w-full columns-2 sm:columns-3 lg:columns-4 2xl:columns-5 pb-[40px] absolute top-20;
    /* @apply text-2xl; */
}
.souvenir-container img{
    @apply pt-5;
}
.souvenir-footer{
    @apply w-full h-[200px] absolute bottom-0 bg-black;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
}